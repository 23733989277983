document.addEventListener('turbolinks:load', function() {
  var rsvp = document.querySelector(".rsvp");
  if (!rsvp) { return; };

  function plusOneDiv() {
    var div = document.createElement('div');
    div.classList.add("col-6");
    div.classList.add("col-sm-12");
    div.classList.add("centered");
    div.classList.add("plus-one");
    div.innerHTML = "<input class='form-input plus-one-name' type='text' placeholder='Name'><a href='#remove' class='btn btn-clear float-right close remove-guest'></a><div class='clear'></div>";
    return div;
  };

  function bindRemoveButtons() {
    document.querySelectorAll("a.remove-guest").forEach(function(elem, i) {
      elem.addEventListener('click', function(event) {
        event.preventDefault();
        var parent = elem.parentNode;
        if (!parent || !parent.parentNode) { return; }
        var partySize = document.querySelector("#rsvp_party_size").value - 0;
        partySize -= 1;
        parent.parentNode.removeChild(parent);
        updatePartySizeLabel(partySize);
      });
    });
  }

  function updatePartySizeLabel(partySize) {
    document.querySelector("#rsvp_party_size").value = partySize;
    document.querySelector("span#number-in-party").innerHTML = partySize;
  }

  function addButtonListener(event) {
    var target = document.querySelector("#plus-ones");
    target.classList.add("form-group");
    target.appendChild(plusOneDiv());
    bindRemoveButtons()
    var partySize = document.querySelector("#rsvp_party_size").value - 0;
    partySize += 1;
    updatePartySizeLabel(partySize);
  }

  function onFormSubmit(event) {
    var plusOnes = [];
    document.querySelectorAll(".plus-one-name").forEach(function(elem, i) {
      plusOnes.push(elem.value);
    });
    document.querySelector("#rsvp_plus_ones").value = plusOnes.join(",");
  }

  function bindFormSubmit() {
    document.querySelector("form.rsvp-form").onsubmit = onFormSubmit;
  }
  bindFormSubmit();

  document.querySelector(".add-someone").addEventListener('click', addButtonListener);

  document.querySelectorAll(".attending label.btn").forEach(function(elem, i) {
    elem.addEventListener('click', function(event) {
      document.querySelectorAll(".attending label.btn").forEach(function(elem, i) {
        elem.classList.remove("btn-primary");
      });
      elem.classList.remove("btn-default");
      elem.classList.add("btn-primary");
    })
  });
});
